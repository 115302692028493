<template>
	<screen class="integration-view" size="medium">
		<h1 slot="title">Link BigCommerce</h1>
		<div slot="body">
			<p>Would you like to link your BigCommerce account to RoundUp?</p>
			<ui-button
				@click="confirmLink"
				class="blue shadow"
				:loading="loading"
				>Link BigCommerce</ui-button
			>
			<ui-button @click="$router.push('/')" class="white shadow"
				>Cancel</ui-button
			>
		</div>
		<modal
			v-model="loading"
			size="medium"
			:showClose="false"
			:allowBackgroundClose="false"
		>
			<h1 style="line-height: 1em; margin-bottom: 0">
				We're doing magic. Hold please.
			</h1>
			<p style="font-size: 14px; font-weight: 400; margin-top: 10px">
				This could take up to 60 seconds...
			</p>
			<div
				style="
					position: relative;
					display: block;
					width: 100px;
					height: 75px;
					margin-bottom: 10px;
				"
			>
				<loader size="big" :loading="true" />
			</div>
			<h1>{{ loadingText }}</h1>
		</modal>
	</screen>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import UiLabel from '../../ui/Label'
import UiButton from '../../ui/Button'
import Modal from '../../ui/Modal'
import Loader from '../../ui/Loader'

export default {
	name: 'bigcommerce-view',
	components: { Screen, UiButton, UiLabel, Modal, Loader },
	data() {
		return {
			isOnboarding: false,
			loading: false,
			loadingIndex: 0,
			timeout: null,
			loadingPhrases: [
				'Abracadabra...',
				'Pulling a rabbit from a hat...',
				'Hockus Pocus...',
				'Escaping a milk jar...',
				'Alakazam...',
				'Cutting an assistant in half...'
			]
		}
	},
	computed: {
		...mapState({
			merchant: ({ merchant }) => merchant.item
		}),
		loadingText() {
			return this.loadingPhrases[this.loadingIndex]
		}
	},
	methods: {
		...mapActions(['getMerchantDetails', 'linkBigCommerce']),
		confirmLink() {
			this.loading = true
			this.refreshLoadingText()
			this.linkBigCommerce(false)
				.then(() => {
					this.loading = false
					this.$router.push({ name: 'steps' })
				})
				.catch(({ response }) => {
					this.loading = false
					this.isLoading = false
					this.$swal('Error', response.data.message)
				})
		},
		refreshLoadingText() {
			this.timeout = setInterval(() => {
				this.loadingIndex =
					(this.loadingIndex + 1) % this.loadingPhrases.length
			}, 3000)
		}
	},
	mounted() {
		this.getMerchantDetails()
	},
	unmounted() {
		clearInterval(this.timeout)
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.integration-view.screen {
	.screen-body {
		padding: 20px 40px;
	}
}
</style>
